<template>
  <v-container fluid>
    <template>
      <v-breadcrumbs>
        <template>
          <v-breadcrumbs-item>
            <h3>CONFIGURACIÓN</h3>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item>
            <v-icon color="primary">mdi-home</v-icon>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item> Permisos </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" lg="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :footer-props="{
            'items-per-page-text': 'Filas por página',
            'items-per-page-options': [5,10,15]
          }"
          :headers="headers"
          :items="events"
          :items-per-page="15"
          :search="search"
          item-key="id"
        >
          <template v-slot:item.state="{ item }">
            <v-chip outlined :color="eventStateColor(item.state.name)">
              {{ item.state.name || "SIN ESTADO ASIGNADO" }}
            </v-chip>
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu left bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="editCase(item.id)">
                  <v-list-item-title>
                    <v-icon class="mr-2" small>mdi-circle-edit-outline</v-icon>
                    Editar
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { permissionsList } from "../../services/configService";
import Swal from "sweetalert2";
import getEventStateColor from "../../utils/eventStateColors";

export default {
  name: "Permission.vue",
  data() {
    return {
      municipalities: [],
      territories: [],
      sidewalks: [],
      events: [],
      search: "",
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: "DESCRIPCIÓN", value: "name" },
        { text: "CÓDIGO", value: "codename" },
      ],
    };
  },
  methods: {
    eventStateColor(state) {
      return getEventStateColor(state) || "gray";
    },
    editCase(id) {
      this.$router.push({
        name: "humansRights.editEventTypeRequiresOption",
        params: {
          id: id,
        },
      });
    },
  },
  async created() {
    try {
      this.showLoader();
      const listEventsResponse = (await permissionsList())?.data;
      this.events = listEventsResponse;
      this.hideLoader();
    } catch (err) {
      this.hideLoader();
      console.error(err);
      Swal.fire(
        "Error",
        "ha ocurrido un error al procesar la solicitud",
        "error"
      );
    }
  },
};
</script>

<style scoped></style>
