<template>
  <v-container fluid>
    <template>
      <v-breadcrumbs>
        <template>
          <v-breadcrumbs-item>
            <h3>CONFIGURACIÓN</h3>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item>
            <v-icon color="primary">mdi-home</v-icon>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item> Roles </v-breadcrumbs-item>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            rounded
            class="mb-2"
            :to="{
              name: 'generalConfig.createRole',
            }"
          >
            Nuevo rol
          </v-btn>
        </template>
      </v-breadcrumbs>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" lg="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :footer-props="{
            'items-per-page-text': 'Filas por página',
            'items-per-page-options': [5,10,15]
          }"
          :headers="headers"
          :items="events"
          :items-per-page="15"
          :search="search"
          item-key="id"
        >
          <template v-slot:item.state="{ item }">
            <v-chip outlined :color="eventStateColor(item.state.name)">
              {{ item.state.name || "SIN ESTADO ASIGNADO" }}
            </v-chip>
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu left bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="show(item)">
                  <v-list-item-title>
                    <v-icon class="mr-2" small>mdi-circle-edit-outline</v-icon>
                    Gestionar
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="editCase(item.id)">
                  <v-list-item-title>
                    <v-icon class="mr-2" small>mdi-circle-edit-outline</v-icon>
                    Editar
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <modal
      :maxWidth="900"
      :persistent="true"
      title="Asignar permisos"
      ref="modalDialogRoles"
    >
      <template #default>
        <div class="py-5">
          <v-col class="d-flex" cols="12" sm="12" lg="12" md="12">
            <v-text-field
              v-model="role.name"
              label="Nombre"
              required
              disabled
            ></v-text-field>
          </v-col>
          <RolePermision
            :type="'roles'"
            :ownerName="role.name"
            :roleId="role.id"
            ref="rolePermission"
            @onDrop="setPermissions"
          ></RolePermision>
        </div>
      </template>
      <template #actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="save()"
          color="#416b4a"
          style="color: white"
          elevation="2"
          >Guardar</v-btn
        >
      </template>
    </modal>
  </v-container>
</template>

<script>
import { listRoles, syncPermissionByRole } from "../../services/configService";
import Swal from "sweetalert2";
import getEventStateColor from "../../utils/eventStateColors";
import RolePermision from "./role-permission";
import Modal from "../Modal.vue";
import sweetalert from "sweetalert2";

export default {
  name: "Roles.vue",
  components: { Modal, RolePermision },
  data() {
    return {
      municipalities: [],
      territories: [],
      sidewalks: [],
      events: [],
      search: "",
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: "NAME", value: "name" },
        { text: "ACCIÓN", value: "action", sortable: false },
      ],
      role: {
        name: "",
        guard_name: "web",
      },
      currentPermissions: [],
    };
  },
  methods: {
    async save() {
      try {
        if (!this.role.name) {
          return;
        }

        this.showLoader();
        let methodDescription = "creado";
        if (!this.role.update) {
          //await permissionService.storeRole({...this.role, permissions: this.currentPermissions});
        } else {
          //await permissionService.updateRole(this.role);
          //await permissionService.syncPermissionByRole(this.role, {permissions: this.currentPermissions});
          methodDescription = "actualizado";
        }
        await syncPermissionByRole(this.role.id, {
          permissions: this.currentPermissions,
        });
        this.resetForm();
        this.$refs.modalDialogRoles.closeModal();
        await sweetalert.fire(
            `Rol ${methodDescription} guardado correctamente`,
          ``,
          "success"
        );
        this.hideLoader();
      } catch (e) {
        this.hideLoader();
        this.$refs.modalDialogRoles.closeModal();
        await sweetalert.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
    setPermissions(permissions) {
      this.currentPermissions = permissions.map((x) => x.id);
    },
    eventStateColor(state) {
      return getEventStateColor(state) || "gray";
    },
    editCase(id) {
      this.$router.push({
        name: "generalConfig.editRoles",
        params: {
          id: id,
        },
      });
    },
    async show(item) {
      this.role = { ...item, update: true };
      //await this.getPermissionsByRole(this.role);
      this.openDialog();
    },
    openDialog() {
      this.$refs.modalDialogRoles.openModal();
      this.$nextTick(function () {
        this.$refs.rolePermission.setPayload();
      });
    },
    resetForm() {
      this.role = {};
    },
  },
  async created() {
    try {
      this.showLoader();
      const listEventsResponse = (await listRoles())?.data;
      this.events = listEventsResponse;
      this.hideLoader();
    } catch (err) {
      this.hideLoader();
      console.error(err);
      Swal.fire(
        "Error",
        "ha ocurrido un error al procesar la solicitud",
        "error"
      );
    }
  },
};
</script>

<style scoped></style>
