<template>
  <v-container fluid>
    <template>
      <v-breadcrumbs>
        <template>
          <v-breadcrumbs-item>
            <h3>CONFIGURACIÓN</h3>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item>
            <v-icon color="primary">mdi-home</v-icon>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item> Usuarios </v-breadcrumbs-item>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            rounded
            class="mb-2"
            :to="{
              name: 'generalConfig.createUser',
            }"
          >
            Nuevo usuario
          </v-btn>
        </template>
      </v-breadcrumbs>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" lg="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :footer-props="{
            'items-per-page-text': 'Filas por página',
            'items-per-page-options': [5,10,15]
          }"
          :headers="headers"
          :items="events"
          :items-per-page="15"
          :search="search"
          item-key="id"
        >
          <template v-slot:item.department_id="{ item }">
              {{ getDepartmentDescription(item.department_id) }}
          </template>
          <template v-slot:item.municipality_id="{ item }">
              {{ getMunicipalityDescription(item.municipality_id) }}
          </template>
          <template v-slot:item.zone_id="{ item }">
            {{ getZoneDescription(item.zone_id) }}
          </template>
          <template v-slot:item.territory_id="{ item }">
            {{ getTerritoryDescription(item.territory_id) }}
          </template>

          <template v-slot:item.action="{ item }">
            <v-menu left bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="editCase(item.id)">
                  <v-list-item-title>
                    <v-icon class="mr-2" small>mdi-circle-edit-outline</v-icon>
                    Editar
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="showPermissions(item)">
                  <v-list-item-title>
                    <v-icon class="mr-2" small>mdi-circle-edit-outline</v-icon>
                    Permisos especiales
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <modal
      :maxWidth="900"
      :persistent="true"
      title="Asignar permisos"
      ref="modalDialog"
    >
      <template #default>
        <div class="py-5">
          <v-col class="d-flex" cols="12" sm="12" lg="12" md="12">
            <v-text-field
              v-model="user.username"
              label="Nombre"
              required
            ></v-text-field>
          </v-col>
          <RolePermision
            :ownerName="user.username"
            :type="'users'"
            ref="userModal"
            :userRole="user"
            @onDrop="setPermissions"
          ></RolePermision>
        </div>
      </template>
      <template #actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="save()"
          color="#416b4a"
          style="color: white"
          elevation="2"
          >Guardar</v-btn
        >
      </template>
    </modal>
  </v-container>
</template>

<script>
import { listUser, syncPermissionByUser } from "../../services/configService";
import Swal from "sweetalert2";
import RolePermision from "./role-permission";
import Modal from "../Modal.vue";
import sweetalert from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "Users.vue",
  components: { Modal, RolePermision },
  data() {
    return {
      municipalities: [],
      territories: [],
      sidewalks: [],
      events: [],
      search: "",
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: "NAME", value: "first_name" },
        { text: "APELLIDO", value: "last_name" },
        { text: "USUARIO", value: "username" },
        { text: "CORREO", value: "email" },
        { text: "TERRITORIO", value: "territory_id" },
        { text: "TIPO USUARIO", value: "type_user" },
        { text: "DEPARTAMENTO", value: "department_id" },
        { text: "MUNICIPIO", value: "municipality_id" },
        { text: "ZONA", value: "zone_id" },
        { text: "ROL", value: "groups[0]" },
        { text: "CREADO EN", value: "date_joined" },
        { text: "ACCIÓN", value: "action", sortable: false },
      ],
      user: {},
      role: {
        name: "",
        guard_name: "web",
      },
      currentPermissions: [],
    };
  },
  computed: {
    ...mapState(["dataSource"]),
  },
  methods: {
    editCase(id) {
      this.$router.push({
        name: "generalConfig.editUser",
        params: {
          id: id,
        },
      });
    },
    async save() {
      try {
        if (!this.user.username) {
          return;
        }
        this.showLoader();
        await syncPermissionByUser(this.user.id, {
          permissions: this.currentPermissions,
        });
        this.resetForm();
        this.$refs.modalDialog.closeModal();
        await sweetalert.fire(
          "Registro actualizado correctamente",
          ``,
          "success"
        );
        this.hideLoader();
      } catch (e) {
        this.hideLoader();
        this.$refs.modalDialog.closeModal();
        await sweetalert.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
    resetForm() {
      this.user = {};
    },
    async showPermissions(item) {
      this.user = { ...item, update: true };
      //await this.getPermissionsByRole(this.role);
      this.openDialog();
    },
    setPermissions(permissions) {
      this.currentPermissions = permissions.map((x) => x.id);
    },
    openDialog() {
      this.$refs.modalDialog.openModal();
      this.$nextTick(function () {
        this.$refs.userModal.setPayload();
      });
    },
    getDepartmentDescription(id) {
      return this.dataSource?.data?.Departamento.find(
        (item) => item.departamento_id == id
      )?.descripcion;
    },
    getMunicipalityDescription(id) {
      return this.dataSource?.data?.Municipio.find(
        (item) => item.municipio_id === id
      )?.descripcion;
    },
    getZoneDescription(id) {
      return this.dataSource?.data?.Zona.find((item) => item.zona_id === id)
        ?.descripcion;
    },
    getTerritoryDescription(id) {
      return this.dataSource?.data?.Territorio.find(
        (item) => item.territorio_id === id
      )?.descripcion;
    },
  },
  async created() {
    try {
      this.showLoader();
      const listEventsResponse = (await listUser())?.data;
      this.events = listEventsResponse;
      this.hideLoader();
    } catch (err) {
      this.hideLoader();
      Swal.fire(
        "Error",
        "ha ocurrido un error al procesar la solicitud",
        "error"
      );
    }
  },
};
</script>

<style scoped></style>
