<template>
  <div>
    <v-subheader color="primary"
      >Mueve de izquierda a derecha para asignar permisos
      {{ userRole ? "especiales" : "" }} a {{ ownerName }}, tambien puedes mover
      de derecha a izquierda para revocarlos</v-subheader
    >

    <div class="d-flex justify-center">
      <v-icon>fas fa-arrows-alt-h</v-icon>
    </div>
    <v-container class="d-flex flex-direction-row justify-center">
      <div class="mx-2 border-all border-danger container-permit">
        <div>
          <div class="card-column-header mb-3 ml-3">
            Stock de permisos
            <v-btn
              elevation="3"
              outlined
              rounded
              text
              x-small
              color="success"
              @click="assignAll"
              >Asignar todos</v-btn
            >
          </div>
          <Container
            group-name="col"
            @drop="(e) => onCardDrop(0, e)"
            :get-child-payload="getCardPayload(0)"
            drag-class="card-ghost"
            drop-class="card-ghost-drop"
            :drop-placeholder="dropPlaceholderOptions"
          >
            <Draggable
              v-for="permission in managementPermissions.children[0]"
              :key="permission.id"
            >
              <div class="item-permission mb-3">
                <h3>{{ permission.name }}</h3>
              </div>
            </Draggable>
            <v-alert
              style="width: 300px"
              v-if="!managementPermissions.children[0].length"
              shaped
              dark
              color="info"
            >
              No existen mas permisos
            </v-alert>
          </Container>
        </div>
      </div>
      <div class="mx-2 border-all container-permit">
        <div>
          <div class="card-column-header mb-3">
            Permisos {{ userRole ? "especiales" : "" }} actuales para
            {{ ownerName }}
          </div>
          <Container
            group-name="col"
            @drop="(e) => onCardDrop(1, e)"
            :get-child-payload="getCardPayload(1)"
            drag-class="card-ghost"
            drop-class="card-ghost-drop"
            :drop-placeholder="dropPlaceholderOptions"
          >
            <Draggable
              v-for="target in managementPermissions.children[1]"
              :key="target.id"
            >
              <div class="item-permission-current mb-3">
                <h3>{{ target.name || target }}</h3>
              </div>
            </Draggable>
            <v-alert
              style="width: 300px"
              v-if="!managementPermissions.children[1].length"
              shaped
              dark
              color="info"
            >
              no hay permisos {{ userRole ? "especiales" : "" }} para
              {{ ownerName }}
            </v-alert>
          </Container>
        </div>
      </div>
      <div class="mx-2 border-all container-permit" v-if="type != 'roles'">
        <div>
          <div class="card-column-header mb-3">
            Permisos actuales del rol {{ userRole?.groups[0] }}
          </div>
          <div>
            <div
              v-for="permissionByRoleWhenIsUser in permissionsByRoleWhenIsUser"
              :key="permissionByRoleWhenIsUser.id"
            >
              <div class="item-disable mb-3">
                <h3>{{ permissionByRoleWhenIsUser.name }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
import { Container, Draggable } from "vue-dndrop";
import { applyDrag, scene } from "../../utils/helpers";
import {
  permissionsList,
  permissionsByRole,
  showUser,
} from "../../services/configService";

export default {
  props: ["ownerName", "userRole", "roleId", "type"],
  name: "Cards",
  components: { Container, Draggable },
  data() {
    return {
      scene,
      dropPlaceholderOptions: {
        className: "drop-preview",
        animationDuration: "150",
        showOnTop: true,
      },
      managementPermissions: { children: [[], []] },
      permissionsByRoleWhenIsUser: [],
      stock: [],
      allPermissionsByRole: [],
      allPermissionsByUser: [],
      allPermissions: [],
      dataUser: [],
    };
  },

  methods: {
    onCardDrop(columnId, dropResult) {
      if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
        const scene = Object.assign({}, this.managementPermissions);
        const column = scene.children[columnId];
        const newColumn = applyDrag(column, dropResult);
        scene.children.splice(columnId, 1, newColumn);
        this.managementPermissions = scene;
        this.$emit("onDrop", this.managementPermissions.children[1]);
      }
    },
    assignAll() {
      this.managementPermissions.children[1] = this.stock;
      this.managementPermissions.children[0] = [];
      this.$set(this.managementPermissions.children, "0", []);
      this.$emit("onDrop", this.stock);
    },
    getCardPayload(columnId) {
      return (index) => {
        return this.managementPermissions.children[columnId][index];
      };
    },
    async setPermissionsInitial() {
      let permissions = await permissionsList();
      this.allPermissions = permissions.data;
    },
    async setPermissionsRole() {
      let permissions = await permissionsByRole(
        this.roleId || this.dataUser?.groups[0]
      );
      this.allPermissionsByRole = permissions.data;
    },
    async setPermissionsUser() {
      let permissions = await showUser(this.userRole.id);
      this.dataUser = permissions.data;
      this.allPermissionsByUser = permissions.data?.user_permissions;
    },
    async setPayload() {
      if (this.userRole) await this.setPermissionsUser();
      await this.setPermissionsRole();
      await this.setPermissionsInitial();

      const targetPermissions = this.userRole
        ? this.allPermissionsByUser
        : this.allPermissionsByRole;
      const mergePermissions = [
        ...this.allPermissionsByUser,
        ...this.allPermissionsByRole,
      ];
      const stockPermissions = this.allPermissions.filter(
        (x) => !mergePermissions.some((item) => item.name === x.name)
      );

      this.managementPermissions.children = [
        ...[stockPermissions],
        ...[targetPermissions],
      ];

      this.stock = stockPermissions;
      if (!this.userRole) {
        this.permissionsByRoleWhenIsUser = [];
        //this.managementPermissions.children[0] = this.allPermissions;
      } else {
        this.permissionsByRoleWhenIsUser = this.allPermissionsByRole;
      }
    },
  },
  created() {
    this.setPayload();
  },
};
</script>
<style scoped>
.border-all {
  border: 1px solid;
  border-style: dotted;
  border-color: #009688;
  padding: 10px;
}
.border-danger {
  border-color: #8a8888ee;
}
.item-permission-current {
  background-color: #009688;
  color: #ffff;
  padding: 4px;
  line-height: 1.2;
  border-radius: 6px;
  cursor: pointer;
}
.item-permission {
  background-color: #eeee;
  padding: 4px;
  line-height: 1.2;
  border-radius: 6px;
  cursor: pointer;
}
.item-disable {
  background-color: rgba(192, 192, 192, 0.933);
  padding: 4px;
  line-height: 1.2;
  border-radius: 6px;
  cursor: no-drop;
  color: #838383;
}
.container-permit {
  max-height: 300px;
  overflow: auto;
}
</style>
