<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title></v-toolbar-title>
    </v-toolbar>
    <v-tabs vertical>
      <v-subheader>Sistema</v-subheader>
      <v-divider></v-divider>
      <v-tab>
        <v-icon left size="medium">fas fa-user-check</v-icon>
        Permisos
      </v-tab>
      <v-tab>
        <v-icon left size="medium">fas fa-user-tag</v-icon>
        Roles
      </v-tab>
      <v-tab>
        <v-icon left size="medium">fas fa-user-tag</v-icon>
        Usuarios
      </v-tab>
      <v-tab>
        <router-link to="/" class="text-decoration-none">
          <v-icon left size="medium">mdi-arrow-left-circle</v-icon>
          Volver a componentes
        </router-link>
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <Permission></Permission>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <Roles></Roles>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <Users></Users>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>
<script>
import Permission from "./Permission";
import Roles from "./Roles";
import Users from "./Users";

export default {
  components: {
    Permission,
    Roles,
    Users,
  },
  data() {
    return {};
  },
  computed: {},
  beforeMount() {},
};
</script>
<style scoped>
.v-tab {
  justify-content: start !important;
}
</style>
